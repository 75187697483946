<template>
  <div class="first">
    <div class="BBg" :style="{ height: isheight }" @click="outwuye"></div>
    <div class="flex_between bbe9s">
      <div class="title f-left p10">订单指派</div>
    </div>
    <!-- 任务查找与筛选 -->
    <div class="mt10">
      <div class="block">
        <!-- <div class="title f-left p10">任务查找与筛选</div> -->
        <div :style="{ display: isindex[1].tt == 't1' ? 'none' : '' }">
          <div class="flex_warp pb20" style="overflow: inherit !important">
            <!-- 城市筛选 -->
            <div class="blocks2 flex_start flex_items m-l-10">
              <span class="mr10">城市筛选</span>
              <el-select
                v-model="citykind"
                placeholder="选择城市"
                style="width: 127px"
                clearable
                @change="getwhere(citykind)"
                class="littleBox"
              >
                <!-- @clear="clearCity()" -->
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item.city"
                  :value="item.city_id"
                ></el-option>
              </el-select>
            </div>
            <!-- 物业 -->
            <div
              v-if="citykind != ''"
              class="blocks2 flex_start flex_items pos"
            >
              <span class="mr10">选择物业</span>
              <el-input
                v-model="wuyeVal"
                placeholder="请选择物业"
                @focus="getwuye"
                style="width: 170px"
                :clearable="true"
                class="littleBox"
              ></el-input>
              <ul
                v-if="wuyekuang && NewItems.length != 0"
                class="abs wuyekuang"
              >
                <li
                  v-for="(item, index) in NewItems"
                  :key="index"
                  :value="item.username"
                  v-text="item.username"
                  @click="chosewuye(index)"
                ></li>
              </ul>
              <p
                class="zanwu"
                v-if="NewItems.length == 0 && nowuye"
                @click="chosezanwu"
              >
                --暂无数据--
              </p>
            </div>
            <!-- 筛选订单 -->
            <div class="blocks2 flex_items">
              <el-input
                placeholder="请输入内容"
                v-model="chosecon"
                class="input-with-select littleBox"
              >
                <el-select
                  v-model="selectkind"
                  slot="prepend"
                  placeholder="请选择类别"
                  style="width: 120px"
                  class="littleBox"
                >
                  <el-option label="联系人" value="contacts"></el-option>
                  <el-option label="联系方式" value="telephone"></el-option>
                  <!-- 2023.2.2添加的2个字段 -->
                  <el-option label="订单号" value="order_no"></el-option>
                  <el-option label="维修地址" value="addres"></el-option>
                </el-select>
              </el-input>
            </div>
            <!-- 筛选 -->
            <div class="blocks2 flex_items">
              <el-button
                type="primary"
                size="medium"
                plain
                style="margin-right: 30px"
                @click="shaixuan"
                >筛选</el-button
              >
              <!-- <el-button type="warning" @click="Popupbox('add')"
                >录入订单</el-button
              > -->
            </div>
          </div>
        </div>
        <!-- <div>
          <span class="f13 mt10 Shrink" @click="Shrink(2)" style="color:#aba9a9">{{isindex[2].msg}}</span>
        </div>-->
      </div>
    </div>
    <!-- 任务列表 -->
    <div class="t1">
      <div class="block flex_column">
        <!-- <div class="title f-left p10">任务列表</div> -->
        <div class="mt10">
          <el-table
            :data="tableData"
            border
            :header-cell-style="{ color: '#252525' }"
          >
            <el-table-column prop="cc_id" label="序号"></el-table-column>
            <el-table-column prop="order_no" label="订单号"></el-table-column>
            <el-table-column label="任务类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 0">指派任务</span>
                <span v-if="scope.row.type == 1">超时未接单</span>
                <span v-if="scope.row.type == 2">打卡未报价</span>
                <span v-if="scope.row.type == 3">施工超期</span>
                <span v-if="scope.row.type == 4">完工回访</span>
                <span v-if="scope.row.type == 5">差评与投诉</span>
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人"></el-table-column>
            <el-table-column
              prop="telephone"
              label="联系方式"
            ></el-table-column>
            <el-table-column prop="addres" label="维修地址"></el-table-column>
            <el-table-column prop="states" label="订单状态"></el-table-column>
            <el-table-column
              prop="throughTime"
              label="下次跟进"
            ></el-table-column>
            <el-table-column
              prop="throughCount"
              label="沟通次数"
            ></el-table-column>
            <!-- <el-table-column prop="store_name" label="店铺"></el-table-column> -->
            <el-table-column label="锁定">
              <template slot-scope="scope" prop="adminUsername">
                <div
                  v-if="
                    scope.row.adminUsername == null ||
                    scope.row.adminUsername == undefined ||
                    scope.row.adminUsername == ''
                  "
                >
                  <el-tag type="success" @click="lockOrder(scope.row)"
                    >锁定</el-tag
                  >
                </div>
                <div v-else>
                  <el-tag type="danger">已锁定</el-tag>
                  <div class="locked">
                    由({{ scope.row.adminUsername }})锁定
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作人员">
              <template slot-scope="scope">
                <span v-if="scope.row.admin_ids == ''">暂无操作人员</span>
                <span v-else>{{ scope.row.admin_ids }}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" align="center" min-width="100">
              <template slot-scope="scope">
                <!-- <el-button
                  v-if="scope.row.TaskStatus == 1"
                  type="info"
                  class="mt10 ml10"
                  >已完成</el-button
                > -->
                <!-- <el-button type="primary" @click="Popupbox('assign',scope.row)">处理任务测试</el-button> -->
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="
                    (scope.row.type == 20 || scope.row.type == 0) &&
                    scope.row.TaskStatus == 0
                  "
                  type="primary"
                  @click="Popupbox('AssignOrder', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 2 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('door', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 3 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('signing', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  v-if="scope.row.type == 1 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('longrange', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 4 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('building', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 7 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('completed', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 10 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('takecomplaints', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="
                    (scope.row.type == 8 || scope.row.type == 9) &&
                    scope.row.TaskStatus == 0
                  "
                  type="primary"
                  @click="Popupbox('cancelrecall', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  size="mini"
                  plain
                  class="mt10 ml10"
                  v-if="scope.row.type == 11 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('aftersales', scope.row)"
                  >处理任务</el-button
                >
                <!-- 查看订单详情 -->
                <el-button
                  size="mini"
                  plain
                  type="warning"
                  @click="chakan(scope.row, 'chakan')"
                  class="mt10 ml10"
                  >查看订单</el-button
                >
                <!-- 转派 -->
                <!-- <el-button
                  v-if="scope.row.TaskStatus != 1"
                  type="success"
                  @click="zhuandan(scope.row, 'zhuandan')"
                  class="mt10 ml10"
                  >转派</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 页码 -->
    <div class="flex_start mt20 flex_items">
      <span class="demonstration mr20">
        总共
        <span class="f-red">{{ count }}</span
        >条数据,当前 <span class="f-red">{{ tableData.length }}</span
        >条
      </span>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="20"
        :total="count"
        layout="prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisible"
        append-to-body
        :close-on-click-modal="false"
        width="80%"
        @open="diagopen()"
        @close="diagclose()"
      >
        <Add v-if="dialogname == 'add'" @refreshEvent="getDataAgain"></Add>
        <Door v-if="dialogname == 'door'" @refreshEvent="getDataAgain"></Door>
        <Signing
          v-if="dialogname == 'signing'"
          @refreshEvent="getDataAgain"
        ></Signing>
        <Longrange
          v-if="dialogname == 'longrange'"
          @refreshEvent="getDataAgain"
        ></Longrange>
        <Building
          v-if="dialogname == 'building'"
          @refreshEvent="getDataAgain"
        ></Building>
        <Completed
          v-if="dialogname == 'completed'"
          @refreshEvent="getDataAgain"
        ></Completed>
        <Takecomplaints
          v-if="dialogname == 'takecomplaints'"
          @refreshEvent="getDataAgain"
        ></Takecomplaints>
        <Cancelrecall
          v-if="dialogname == 'cancelrecall'"
          @refreshEvent="getDataAgain"
        ></Cancelrecall>
        <AssignOrder
          v-if="dialogname == 'AssignOrder'"
          @refreshEvent="getDataAgain"
        ></AssignOrder>
        <Detailes2
          v-if="dialogname == 'chakan'"
          @refreshEvent="getDataAgain"
        ></Detailes2>
        <aftersales
          v-if="dialogname == 'aftersales'"
          @refreshEvent="getDataAgain"
        ></aftersales>
        <works
          v-if="dialogname == 'mingxi'"
          @refreshEvent="getDataAgain"
        ></works>
        <waitDeal
          v-if="dialogname == 'waitDetails'"
          @refreshEvent="getDataAgain"
        ></waitDeal>
        <finishDeal
          v-if="dialogname == 'finishDetails'"
          @refreshEvent="getDataAgain"
        ></finishDeal>
        <urgentDetails
          v-if="dialogname == 'urgentDetails'"
          @refreshEvent="getDataAgain"
        ></urgentDetails>
        <dealVisitDetails
          v-if="dialogname == 'dealVisitDetails'"
          @refreshEvent="getDataAgain"
        ></dealVisitDetails>
        <hangupDetails
          v-if="dialogname == 'hangupDetails'"
          @refreshEvent="getDataAgain"
        ></hangupDetails>
      </el-dialog>
    </div>
    <!-- 转派弹框 -->
    <!-- 转派 -->
    <div v-if="dialogTableVisible2" class="zhuanzhuan">
      <el-dialog :visible.sync="dialogTableVisible2">
        <div class="title f-left plr10 pb10 bbe9s">转派订单</div>
        <!-- 转派客服 -->
        <div class="flex_items mt10 pos">
          <span class="mr20" style="margin-left: 30%">转派客服</span>
          <el-select
            class="mr10"
            filterable
            v-model="kefu"
            collapse-tags
            placeholder="选择客服"
          >
            <el-option
              v-for="item in kefus"
              :key="item.admin_id"
              :label="item.username"
              :value="item.admin_id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            style="margin: 10px auto"
            @click="tijiaozhuan"
            >转派任务</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import assign from "@/components/Assign.vue";
export default {
  data() {
    return {
      isdiag: false,
      isheight: "",
      activeNames: ["1"],
      Headdata: {},
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "-1",
          msg: "--收起--",
        },
      ],
      // 任务状态
      tasks: [],
      TaskStatus: "",
      time1: "",
      chosecon: "",
      selectkind: "",
      client: "",
      clienton: "",
      citys: [],
      genjin: [],
      jiedan: "",
      citykind: "",
      taskid: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      // 分页数据
      currentPage3: 1,
      tableData: [],
      count: 0,
      // 弹框
      dialogTableVisible: false,
      dialogname: "add",
      task: 0,
      shoppers: [],
      shopper: "",
      dialogTableVisible2: false,
      kefus: [],
      kefu: "",
      kefucc_id: "",
      wechat: "",
      shengid: "",
      details: {
        province_id: "",
        city_id: "",
        county_id: "",
      },
      adminUsername: null,
    };
  },
  mounted() {
    this.clienton = sessionStorage.getItem("admin");
    this.getLists();
    this.getcitys();
  },
  methods: {
    // 锁定订单
    lockOrder(row, username) {
      this.util
        .get(this.HOST + "/User/locking", {
          cc_id: row.cc_id,
        })
        .then((res) => {
          //console.log(res, "~~~~~~~~~~~~~");
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "锁定成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.getDataAgain();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 刷新重新获取数据
    getDataAgain() {
      this.$loading(this.util.opload);
      (this.time1 = ""),
        (this.citykind = ""),
        (this.wuyeVal = ""),
        (this.wechat = ""),
        // this.taskid = "",
        (this.client = ""),
        (this.shopper = ""),
        // this.chosecon = "",
        this.util
          .get(this.HOST + "/User/ccList", {
            start_time: undefined,
            end_time: undefined,
            city_id: undefined,
            store_id: undefined,
            full: undefined,
            chen: undefined,
            contacts: undefined,
            assignor: undefined,
            complaints: 0,
            status: 20,
            residential_id: undefined,
            wechat: undefined,
            page: this.currentPage3,
            admin_id: 0,
            limit: 20,
            type: 0,
            types: 0,
          })
          .then((res) => {
            if (res.code == 200) {
              this.count = res.data.count;
              this.tableData = res.data.data;
              this.$loading().close();
              this.getLists();
            } else {
              this.$loading().close();
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
    },
    //执行挂起与取消挂起操作
    doGua(row, hangUp) {
      if (row.order_id == null) {
        this.$message({
          showClose: true,
          message: "订单编号不存在!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.util
        .get(this.HOST + "/Cc/HangUp", {
          cc_id: row.cc_id,
          hang_up: hangUp,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "挂起状态操作成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.getDataAgain();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 城市
    getcitys() {
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
          // this.wuyes();
          this.gettasks();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 筛选城市之后请求物业和店长
    getwhere(citykind) {
      //console.log(this.group_id, this.shopper);
      this.wuyeVal = "";
      this.shopper = "";
      this.order_id = "";
      // this.util
      // .get(this.HOST + "/Common/chanel", {
      // city_id: this.citykind,
      // })
      // .then((res) => {
      // if (res.code == 200) {
      // this.qudaos = res.data;
      if (citykind) {
        this.citys.map((item) => {
          if (citykind == item.city_id) {
            this.shengid = item.province_id;
          }
        });
        this.wuyes();
        this.shop("aa");
      }
      // }
      // });
    },
    // 获取物业
    wuyes() {
      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: this.citykind,
          //uu: this.citykind,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 任务类型
    gettasks() {
      this.util.get(this.HOST + "/Common/service", {}).then((res) => {
        if (res.code == 200) {
          this.tasks = res.data;
          //   this.getclient();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 获取店长
    shop(aa) {
      this.shoppers = [];
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: this.citykind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            // this.getLists();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 列表
    getLists() {
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/User/ccList", {
          page: this.currentPage3,
          admin_id: 0,
          limit: 20,
          type: 0,
          types: 0,
          SelectedStatus: 1,
          status: 20,
          complaints: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.data;
            this.$store.commit("settask", 0);
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.$loading(this.util.opload);
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.tableData = [];
      let admin_id = 0;
      if (this.client != "") {
        admin_id = 0;
      }
      this.util
        .get(this.HOST + "/User/ccList", {
          page: this.currentPage3,
          start_time: time1,
          end_time: time2,
          city_id: this.citykind,
          SelectedStatus: 1,
          status: 20,
          complaints: 0,
          store_id: this.store,
          full: this.Satisfied,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: admin_id,
          assignor: this.shopper,
          residential_id: this.wuyeVal,
          type: 0,
          types: 0,
          wechat: this.wechat,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
    // 弹框
    Popupbox(con, row) {
      this.wuyekuang = false;
      if (con == "assign") {
        sessionStorage.setItem("kind", "assign");
      }
      this.dialogname = con;
      if (row) {
        this.$store.commit("setOrder", row);
        // this.$store.commit("setcc", row);
      }
      this.$store.commit("setMessage", true);
    },
    diagopen() {
      this.$store.commit("setMessage", true);
    },
    diagclose() {
      this.$store.commit("setMessage", false);
    },
    getwuye(event) {
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.isheight = "0px";
      this.nowuye = false;
    },
    chosezanwu() {
      this.nowuye = false;
    },
    shaixuan() {
      this.$loading(this.util.opload);
      if (this.time1 == null) {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.tableData = [];
      this.currentPage3 = 1;
      this.wuyekuang = false;
      let admin_id = 0;
      if (this.client != "") {
        admin_id = 0;
      }
      this.util
        .get(this.HOST + "/User/ccList", {
          start_time: time1,
          end_time: time2,
          city_id: this.citykind,
          SelectedStatus: 1,
          status: 20,
          complaints: 0,
          store_id: this.store,
          full: this.Satisfied,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: admin_id,
          assignor: this.shopper.split("_")[0],
          residential_id: this.wuyeVal,
          type: 0,
          page: 1,
          types: 0,
          wechat: this.wechat,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 查看订单
    chakan(row, con) {
      this.wuyekuang = false;
      this.dialogname = con;
      if (row) {
        this.$store.commit("setOrder", row);
        // //console.log(this.$store.state.order_id+'----------------------')
      }
      this.$store.commit("setMessage", true);
    },
    // 转派
    // zhuandan(row, con) {
    //   this.util.get(this.HOST + "/User/user", {}).then((res) => {
    //     if (res.code == 200) {
    //       this.kefus = res.data;
    //       this.kefucc_id = row.cc_id;
    //       this.dialogTableVisible2 = true;
    //     }
    //   });
    // },

    // 提交转派
    tijiaozhuan() {
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务转派中...",
      });
      this.util
        .get(this.HOST + "/Order/Transfers", {
          cc_id: this.kefucc_id,
          admin_id: this.kefu,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务转派成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("settask", 1);
              this.dialogTableVisible2 = false;
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    NewItems() {
      var _this = this;
      var NewItems = [];
      this.items.map(function (item) {
        if (item.username.search(_this.wuyeVal) != -1) {
          NewItems.push(item);
        }
      });
      return NewItems;
    },
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogTableVisible;
      this.isdiag = this.$store.state.dialogTableVisible;
    },
    // 判断处理成功
    getStoreTask() {
      // if (this.$store.state.task == 1) {
      // this.tableData = [];
      // this.getLists("fen");
      // this.shaixuan()
      // }
    },
    getworks() {
      this.works = this.$store.state.works;
    },
  },
  watch: {
    getStoreItem() {
      // //console.log(this.$store.state.dialogTableVisible, "121212");
    },
    getStoreTask() {
      // //console.log(this.$store.state.task, "121212");
    },
  },
  components: {
    Add: () => import("@/components/addorder.vue"),
    Door: () => import("@/components/door.vue"),
    Signing: () => import("@/components/signing.vue"),
    Longrange: () => import("@/components/Longrange.vue"),
    Building: () => import("@/components/building.vue"),
    Completed: () => import("@/components/Completed.vue"),
    Takecomplaints: () => "@/components/takecomplaints.vue",
    Cancelrecall: () => "@/components/Cancelrecall.vue",
    // Assign: assign,
    AssignOrder: () => import("@/components/AssignOrder.vue"),
    // Detailes: ()=>import('@/components/detailes.vue'),
    Detailes2: () => import("@/components/detailes2.vue"),
    aftersales: () => import("@/components/aftersales.vue"),
    works: () => import("@/components/worksdetail.vue"),
    waitDeal: () => import("@/components/waitDeal.vue"),
    finishDeal: () => import("@/components/finishDeal.vue"),
    urgentDetails: () => import("@/components/urgentDetails.vue"),
    dealVisitDetails: () => import("@/components/dealVisitDetails.vue"),
    hangupDetails: () => import("@/components/hangupDetails.vue"),
  },
};
</script>
<style>
.first {
  margin: 0 auto;
  background: white;
  /* padding: 10px 30px; */
  padding: 15px 0 0 30px;
  border-radius: 8px;
}
.el-main {
  padding: 10px;
}
.title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 18px;
  background: none;
  width: 93%;
}
p {
  margin: 0;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.t2span {
  width: 64px;
}
.conplles {
  height: 76px;
}
.conplles2 {
  height: 0;
}
.Shrink {
  cursor: pointer;
  float: right;
  display: inline-block;
}
.el-input {
  width: auto;
}
/* 物业 */
.wuyekuang {
  top: 34px;
  left: 75px;
  width: 170px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.wuyekuang li:hover {
  background-color: #f5f7fa;
}
li {
  list-style-type: none;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 85px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.el-dialog {
  width: 85%;
}
.littleBox input {
  height: 35px !important;
  line-height: 35px !important;
}
.locked {
  font-size: 12px;
  color: #666;
}
.el-table {
  width: 100%;
}
.el-table__header-wrapper table,
.el-table__body-wrapper table {
  width: 100% !important;
}
.el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto;
}
</style>
